import React from "react";

class Comp1 extends React.Component {
  render() {
    return (
      <ul className="comp1" id={14}>
        <li className="comp1_1" id={22}>
          <a className="comp1_6" href="https://rist.ro/teams/" id={39}>
            Teams
          </a>
        </li>
        <li className="comp1_2" id={23}>
          <a className="comp1_7" href="https://rist.ro/publications/" id={41}>
            Publications
          </a>
        </li>
        <li className="comp1_3" id={24}>
          <a className="comp1_8" href="https://rist.ro/category/news/" id={43}>
            News
          </a>
        </li>
        <li className="comp1_4" id={25}>
          <a
            className="comp1_9"
            href="https://rist.ro/about-the-institute/"
            id={45}
          >
            About
          </a>
        </li>
        <li className="comp1_5" id={26}>
          <a className="comp1_10" href="https://rist.ro/contact-en/" id={47}>
            Contact
          </a>
        </li>
      </ul>
    );
  }
}

export default Comp1;
