import React from "react";

class Comp6 extends React.Component {
  render() {
    return (
      <div className="comp6">
        <div className="comp6_1">
          <div
            aria-label="The chronotron"
            className="comp6_5"
            role="button"
            tabIndex={0}
          ></div>
        </div>
        <div className="comp6_2">
          <div
            aria-label="Intricate connections"
            className="comp6_6"
            role="button"
            tabIndex={0}
          ></div>
        </div>
        <div className="comp6_3">
          <div
            aria-current="true"
            aria-label="New metrics"
            className="comp6_7"
            role="button"
          ></div>
        </div>
        <div className="comp6_4">
          <div
            aria-label="Resonance"
            className="comp6_8"
            role="button"
            tabIndex={0}
          ></div>
        </div>
      </div>
    );
  }
}

export default Comp6;
