import React from "react";

class Comp10 extends React.Component {
  render() {
    return (
      <div className="comp10" id={36}>
        <div className="comp10_1" id={74}>
          <h3 className="comp10_2" id={121}>
            Search
          </h3>
          <form
            action="https://rist.ro/"
            className="comp10_3"
            id={122}
            method="get"
            role="search"
          >
            <label className="comp10_4" id={189}>
              <input
                className="comp10_6"
                id={248}
                name="s"
                placeholder="Search \u2026"
                type="search"
                defaultValue
              />
            </label>
            <button className="comp10_5" id={190} type="submit"></button>
          </form>
        </div>
      </div>
    );
  }
}

export default Comp10;
