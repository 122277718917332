import React from "react";

class Comp11 extends React.Component {
  render() {
    return (
      <div className="comp11">
        <p className="comp11_1">
          <a className="comp11_2" href="https://rist.ro/category/news/">
            More news
          </a>
        </p>
      </div>
    );
  }
}

export default Comp11;
