import React from "react";

class Comp14 extends React.Component {
  render() {
    return (
      <div className="comp14" id={249}>
        <div className="comp14_1" id={305}>
          <div className="comp14_2" id={356}>
            <div className="comp14_4" data-mode="fill" id={408}>
              <div className="comp14_6" id={410}>
                <div
                  className="comp14_8"
                  data-blur={0}
                  data-desktop="//rist.ro/wp-content/uploads/2018/09/brains_gradient.jpg"
                  data-hash="f6c84e1a8593dc46816460ef117bca02"
                  id={412}
                ></div>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="comp14_3"
            data-id={18}
            data-slide-duration={0}
            data-title="Resonance"
            id={357}
          >
            <div
              className="comp14_5"
              data-csstextalign="center"
              data-desktopportraitpadding="0|*|0|*|0|*|0"
              data-pm="default"
              data-sstype="slide"
              id={409}
            >
              <div
                className="comp14_7"
                data-adaptivefont={0}
                data-csstextalign="left"
                data-desktoplandscape={1}
                data-desktopportrait={1}
                data-desktopportraitalign="left"
                data-desktopportraitfontsize={100}
                data-desktopportraitgutter={0}
                data-desktopportraitheight={246}
                data-desktopportraitinneralign="left"
                data-desktopportraitleft={18}
                data-desktopportraitpadding="0|*|10|*|10|*|10|*|px+"
                data-desktopportraitparentalign="center"
                data-desktopportraitparentvalign="middle"
                data-desktopportraittop={44}
                data-desktopportraitvalign="top"
                data-desktopportraitwidth={417}
                data-desktopportraitwrapafter={0}
                data-frontend-fullwidth={1}
                data-mobilelandscape={1}
                data-mobilelandscapewrapafter={1}
                data-mobileportrait={1}
                data-mobileportraitalign="left"
                data-mobileportraitheight={773}
                data-mobileportraitleft={18}
                data-mobileportraitpadding="0|*|10|*|10|*|10|*|px+"
                data-mobileportraittop={-258}
                data-mobileportraitvalign="top"
                data-mobileportraitwidth={899}
                data-mobileportraitwrapafter={1}
                data-parentid
                data-plugin="rendered"
                data-pm="absolute"
                data-responsiveposition={1}
                data-responsivesize={1}
                data-rotation={0}
                data-sstype="row"
                data-tabletlandscape={1}
                data-tabletportrait={1}
                data-tabletportraitalign="left"
                data-tabletportraitheight={269}
                data-tabletportraitleft={13}
                data-tabletportraittop={20}
                data-tabletportraitvalign="top"
                data-tabletportraitwidth={641}
                id={411}
              >
                <div className="comp14_9" id={413} row-wrapped={0}>
                  <div
                    className="comp14_10"
                    data-adaptivefont={0}
                    data-colwidthpercent={100}
                    data-csstextalign="left"
                    data-desktoplandscape={1}
                    data-desktopportrait={1}
                    data-desktopportraitfontsize={100}
                    data-desktopportraitinneralign="left"
                    data-desktopportraitmaxwidth={440}
                    data-desktopportraitorder={0}
                    data-desktopportraitpadding="15|*|15|*|15|*|15|*|px+"
                    data-desktopportraitverticalalign="space-around"
                    data-has-maxwidth={1}
                    data-mobilelandscape={1}
                    data-mobileportrait={1}
                    data-mobileportraitmaxwidth={0}
                    data-mobileportraitpadding="40|*|40|*|40|*|40|*|px+"
                    data-plugin="rendered"
                    data-pm="default"
                    data-r={0}
                    data-rotation={0}
                    data-sstype="col"
                    data-tabletlandscape={1}
                    data-tabletportrait={1}
                    data-tabletportraitmaxwidth={0}
                    data-tabletportraitpadding="10|*|0|*|0|*|15|*|px+"
                    id={415}
                  >
                    <div
                      className="comp14_11"
                      data-verticalalign="space-around"
                      id={416}
                    >
                      <div
                        className="comp14_12"
                        data-adaptivefont={0}
                        data-cssselfalign="inherit"
                        data-desktoplandscape={1}
                        data-desktopportrait={1}
                        data-desktopportraitfontsize={100}
                        data-desktopportraitheight={0}
                        data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+"
                        data-desktopportraitmaxwidth={340}
                        data-desktopportraitselfalign="inherit"
                        data-has-maxwidth={1}
                        data-mobilelandscape={1}
                        data-mobileportrait={1}
                        data-mobileportraitfontsize={295}
                        data-plugin="rendered"
                        data-pm="normal"
                        data-rotation={0}
                        data-sstype="layer"
                        data-tabletlandscape={1}
                        data-tabletportrait={1}
                        data-tabletportraitfontsize={120}
                        id={417}
                      >
                        <div className="comp14_15" id={420}>
                          Resonance
                        </div>
                      </div>
                      <div
                        className="comp14_13"
                        data-adaptivefont={0}
                        data-cssselfalign="left"
                        data-desktoplandscape={1}
                        data-desktopportrait={1}
                        data-desktopportraitfontsize={100}
                        data-desktopportraitheight={0}
                        data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+"
                        data-desktopportraitmaxwidth={385}
                        data-desktopportraitselfalign="left"
                        data-has-maxwidth={1}
                        data-mobilelandscape={1}
                        data-mobileportrait={1}
                        data-mobileportraitfontsize={300}
                        data-plugin="rendered"
                        data-pm="normal"
                        data-rotation={0}
                        data-sstype="layer"
                        data-tabletlandscape={1}
                        data-tabletportrait={1}
                        data-tabletportraitfontsize={130}
                        data-tabletportraitmaxwidth={600}
                        id={418}
                      >
                        <div className="comp14_16" id={421}>
                          <div className="comp14_18" id={424}>
                            <p className="comp14_20" id={426}>
                              of neuronal membrane enables stable and robust
                              gamma oscillations in the brain
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="comp14_14"
                        data-adaptivefont={0}
                        data-cssselfalign="inherit"
                        data-desktoplandscape={1}
                        data-desktopportrait={1}
                        data-desktopportraitfontsize={100}
                        data-desktopportraitheight={0}
                        data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+"
                        data-desktopportraitmaxwidth={225}
                        data-desktopportraitselfalign="inherit"
                        data-has-maxwidth={1}
                        data-mobilelandscape={1}
                        data-mobileportrait={1}
                        data-plugin="rendered"
                        data-pm="normal"
                        data-rotation={0}
                        data-sstype="layer"
                        data-tabletlandscape={1}
                        data-tabletportrait={1}
                        id={419}
                      >
                        <div className="comp14_17" id={422}>
                          <a
                            className="comp14_19"
                            href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
                            id={425}
                            tabIndex={-1}
                          >
                            <div className="comp14_21" id={427}>
                              <div className="comp14_22" id={429}>
                                Find out more
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Comp14;
