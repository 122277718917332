import React from 'react';
import Slot from '../pageframework/Slot';

//TODO: move into /data & have it automatically extracted from CSE
class HifiRistTemplate extends React.Component {
  render() {
    return (
      /* eslint-disable */
      <div>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="profile" href="http://gmpg.org/xfn/11" />
        <title>Romanian Institute of Science and Technology</title>
        <meta property="og:image" content="https://rist.ro/wp-content/uploads/2018/09/header-bg-6.gif" />
        <meta property="og:image:width" content={940} />
        <meta property="og:image:height" content={120} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Romanian Institute of Science and Technology" />
        <meta property="og:url" content="https://rist.ro/" />
        <meta property="og:site_name" content="Romanian Institute of Science and Technology" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Romanian Institute of Science and Technology" />
        <meta name="twitter:image" content="https://rist.ro/wp-content/uploads/2018/09/header-bg-6.gif" />
        <meta name="twitter:image:width" content={940} />
        <meta name="twitter:image:height" content={120} />
        <link rel="canonical" href="https://rist.ro/" />
        <link rel="next" href="https://rist.ro/page/2/" />
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
        <link rel="dns-prefetch" href="//s.w.org" />
        <link rel="alternate" type="application/rss+xml" title="Romanian Institute of Science and Technology » Feed" href="https://rist.ro/feed/" />
        <link rel="alternate" type="application/rss+xml" title="Romanian Institute of Science and Technology » Comments Feed" href="https://rist.ro/comments/feed/" />
        <link rel="stylesheet" id="wp-block-library-css" href="https://rist.ro/wp-includes/css/dist/block-library/style.min.css?ver=5.4.2" type="text/css" media="all" />
        <link rel="stylesheet" id="rist-fonts-css" href="https://fonts.googleapis.com/css?family=Merriweather%3A400%2C700%2C900%2C400italic%2C700italic%2C900italic%7CMontserrat%3A400%2C700%7CInconsolata%3A400&subset=latin%2Clatin-ext" type="text/css" media="all" />
        <link rel="stylesheet" id="genericons-css" href="https://rist.ro/wp-content/themes/rist/genericons/genericons.css?ver=3.4.1" type="text/css" media="all" />
        <link rel="stylesheet" id="rist-style-css" href="https://rist.ro/wp-content/themes/rist/style.css?ver=5.4.2" type="text/css" media="all" />
        <link rel="stylesheet" type="text/css" href="https://rist.ro/wp-content/plugins/smart-slider-3/Public/SmartSlider3/Application/Frontend/Assets/dist/smartslider.min.css?ver=70fceec4" media="all" />
        <link rel="https://api.w.org/" href="https://rist.ro/wp-json/" />
        <link rel="EditURI" type="application/rsd+xml" title="RSD" href="https://rist.ro/xmlrpc.php?rsd" />
        <link rel="wlwmanifest" type="application/wlwmanifest+xml" href="https://rist.ro/wp-includes/wlwmanifest.xml" />
        <link rel="alternate" href="https://rist.ro/ro/" hrefLang="ro" />
        <link rel="alternate" href="https://rist.ro/" hrefLang="en" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400%7COpen+Sans:300,400&subset=latin" media="all" />
        <div id="page" className="site">	<a className="skip-link screen-reader-text" href="#content">Skip to content</a>
          <Slot name="header" />
          <div id="site-header-menu" className="site-header-menu" style={{ display: 'none' }}>
            <nav id="site-navigation" className="main-navigation" role="navigation" aria-label="Primary Menu">
              <div className="menu-meniu-principal-en-container">
                <Slot name="nav"/>
              </div>
            </nav>
          </div>
          <div id="navigation" className="central-menu">
            <div className="menu-meniu-principal-en-container">
              <Slot name="nav"/>
            </div>
          </div>
          <div className="site-inner">
            <div id="content" className="site-content">
              <div id="slider">
                <div className="n2-section-smartslider fitvidsignore" role="region" aria-label="Slider">
                  <div id="n2-ss-4-align" className="n2-ss-align n2-ss-align-visible">
                    <div className="n2-padding" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                      <div id="n2-ss-4" data-creator="Smart Slider 3" className="n2-ss-slider n2-ow n2-has-hover  n2-ss-load-fade n2-ss-loaded n2-ss-tabletPortrait" style={{ fontSize: '15.983px', width: '941px' }} data-fontsize={16} data-device-mode="tabletPortrait">
                        <Slot name="hero"/>
                      </div>
                    </div>
                  </div>
                  <div className="n2_clear" />
                </div>
              </div>
              <div id="primary" className="content-area">
                <main id="main" className="site-main" role="main">
                  <header className="page-header">
                    <h1 className="page-title" />
                  </header>
                  <Slot name="main"></Slot>
                </main>
              </div>
              <aside id="secondary" className="sidebar widget-area" role="complementary">
                <Slot name="right"/>
              </aside>
            </div>
            <footer id="colophon" className="site-footer" role="contentinfo">
              <div id="footer-container">
                <div className="box columns-3">
                  <div className="column">
                    <div className="content">
                      <Slot name="footerL-header"/>
                      <div className="rte">
                        <div id="footer-social">
                          <Slot name="footerL-social"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <Slot name="footerM"/>{/*was level up*/}
                  </div>
                  <div className="column">
                    <Slot name="footerR"/>{/*was level up*/}
                  </div>
                </div>
                <hr />
                <Slot name="copyright"></Slot>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
};

export default HifiRistTemplate;