import React from "react";

class Comp4_3 extends React.Component {
  render() {
    return (
      <article className="comp4_3" id={52}>
        <div className="comp4_3_1" id={85}>
          <div className="comp4_3_2" id={132}>
            <a
              aria-hidden="true"
              className="comp4_3_4"
              href="https://rist.ro/the-latest-neuromorphic-chip-launched-by-intel-implements-learning-rules-developed-at-the-romanian-institute-of-science-and-technology/"
              id={198}
            >
              <img
                alt="The latest neuromorphic chip launched by Intel implements learning rules developed at the Romanian Institute of Science and Technology"
                className="comp4_3_7"
                height={140}
                id={259}
                src="https://rist.ro/wp-content/uploads/2018/09/2e74f0caa6.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_3_3" id={133}>
            <header className="comp4_3_5" id={199}>
              <h2 className="comp4_3_8" id={260}>
                <a
                  className="comp4_3_11"
                  href="https://rist.ro/the-latest-neuromorphic-chip-launched-by-intel-implements-learning-rules-developed-at-the-romanian-institute-of-science-and-technology/"
                  id={316}
                  rel="bookmark"
                >
                  The latest neuromorphic chip launched by Intel implements
                  learning rules developed at the Romanian Institute of Science
                  and Technology
                </a>
              </h2>
            </header>
            <div className="comp4_3_6" id={200}>
              <p className="comp4_3_9" id={261}>
                <span className="comp4_3_12" id={317} lang="RO">
                  “synaptic eligibility traces”, components of a learning rule
                  developed by RIST’s R
                </span>
                <span className="comp4_3_13" id={319} lang="RO">
                  ăzvan Florian, which allow the implementation of this learning
                  rule on the chip.
                </span>
              </p>
              <span className="comp4_3_10" id={262}>
                <a
                  className="comp4_3_14"
                  href="https://rist.ro/the-latest-neuromorphic-chip-launched-by-intel-implements-learning-rules-developed-at-the-romanian-institute-of-science-and-technology/"
                  id={320}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_3;
