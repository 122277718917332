import React from "react";

class Comp1 extends React.Component {
  render() {
    return (
      <ul className="comp1">
        <li className="comp1_1">
          <a className="comp1_6" href="https://rist.ro/teams/">
            Teams
          </a>
        </li>
        <li className="comp1_2">
          <a className="comp1_7" href="https://rist.ro/publications/">
            Publications
          </a>
        </li>
        <li className="comp1_3">
          <a className="comp1_8" href="https://rist.ro/category/news/">
            News
          </a>
        </li>
        <li className="comp1_4">
          <a className="comp1_9" href="https://rist.ro/about-the-institute/">
            About
          </a>
        </li>
        <li className="comp1_5">
          <a className="comp1_10" href="https://rist.ro/contact-en/">
            Contact
          </a>
        </li>
      </ul>
    );
  }
}

export default Comp1;
