import React from "react";

class Comp5 extends React.Component {
  render() {
    return (
      <ul className="comp5">
        <li className="comp5_1">
          <a
            className="comp5_5"
            href="https://www.facebook.com/InstitutulRomanDeStiintaSiTehnologie"
            title="Facebook"
          >
            Facebook
          </a>
        </li>
        <li className="comp5_2">
          <a
            className="comp5_6"
            href="https://twitter.com/rist_ro"
            title="Twitter"
          >
            Twitter
          </a>
        </li>
        <li className="comp5_3">
          <a
            className="comp5_7"
            href="http://www.linkedin.com/company/romanian-institute-of-science-and-technology"
            title="LinkedIn"
          >
            LinkedIn
          </a>
        </li>
        <li className="comp5_4">
          <a className="comp5_8" href="http://coneural.rist.ro/rss.html">
            <img
              alt=""
              className="comp5_9"
              height={24}
              src="https://rist.ro/wp-content/themes/rist/icons/rss.png"
              width={24}
            />
          </a>
        </li>
      </ul>
    );
  }
}

export default Comp5;
