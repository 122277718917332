import React from "react";

class Comp4_2 extends React.Component {
  render() {
    return (
      <article className="comp4_2">
        <div className="comp4_2_1">
          <div className="comp4_2_2">
            <a
              aria-hidden="true"
              className="comp4_2_4"
              href="https://rist.ro/the-latest-neuromorphic-chip-launched-by-intel-implements-learning-rules-developed-at-the-romanian-institute-of-science-and-technology/"
            >
              <img
                alt="The Loihi neuromorphic chip launched by Intel implements learning rules developed at the Romanian Institute of Science and Technology"
                className="comp4_2_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2018/09/2e74f0caa6.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_2_3">
            <header className="comp4_2_5">
              <h2 className="comp4_2_8">
                <a
                  className="comp4_2_11"
                  href="https://rist.ro/the-latest-neuromorphic-chip-launched-by-intel-implements-learning-rules-developed-at-the-romanian-institute-of-science-and-technology/"
                  rel="bookmark"
                >
                  The Loihi neuromorphic chip launched by Intel implements
                  learning rules developed at the Romanian Institute of Science
                  and Technology
                </a>
              </h2>
            </header>
            <div className="comp4_2_6">
              <p className="comp4_2_9">
                <span className="comp4_2_12" lang="RO">
                  “synaptic eligibility traces”, components of a learning rule
                  developed by RIST’s R
                </span>
                <span className="comp4_2_13" lang="RO">
                  ăzvan Florian, which allow the implementation of this learning
                  rule on the chip.
                </span>
              </p>
              <span className="comp4_2_10">
                <a
                  className="comp4_2_14"
                  href="https://rist.ro/the-latest-neuromorphic-chip-launched-by-intel-implements-learning-rules-developed-at-the-romanian-institute-of-science-and-technology/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_2;
