import React from 'react';
import './styles/CompsShowcase.css';

var Comps = require('./../pageframework/CompLoader').default

class CompsShowcase extends React.Component {
  render() {
    Comps.load(this.props.extractedSite);

    return (<>
      {Object.keys(Comps.index).map((key) => {
        return <div>
                  <h1 onClick={ () => {
                      console.log(Reflect.get(React.createElement(Comps[Comps.index[key][0]]), 'type'))
                  }}>{key}</h1>
                  <ul className="ComponentsList">
                    {Comps.index[key].map((comp) => {
                      return <li>
                                <div className="CompContainer">
                                  {React.createElement(Comps[comp])}
                                </div>
                              </li>
                      })}
                  </ul>
                </div>
      })}
    </>);
  }
};

export default CompsShowcase;
