import React from "react";

class Comp2_2 extends React.Component {
  render() {
    return (
      /* eslint-disable */
      <section className="comp2_2" id={30}>
        <h2 className="comp2_2_1" id={65}>
          Joining our team
          <img
            className="comp2_2_3"
            height={26}
            id={100}
            src="https://rist.ro/wp-content/themes/rist/icons/icon1_23.png"
            width={26}
          />
        </h2>
        <div className="comp2_2_2" id={66}>
          <p className="comp2_2_4" id={101}>
            We welcome new scientists who would like to join our institute as
            principal investigators.
            <a
              className="comp2_2_5"
              href="https://rist.ro/call-for-scientists/"
              id={165}
            >
              .
            </a>
          </p>
        </div>
      </section>
    );
  }
}

export default Comp2_2;
