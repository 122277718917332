import React from "react";

class Comp4_4 extends React.Component {
  render() {
    return (
      <article className="comp4_4" id={53}>
        <div className="comp4_4_1" id={86}>
          <div className="comp4_4_2" id={135}>
            <a
              aria-hidden="true"
              className="comp4_4_4"
              href="https://rist.ro/understanding-the-mechanisms-of-alcohol-addiction-by-analyzing-brain-networks/"
              id={201}
            >
              <img
                alt="Understanding the mechanisms of alcohol addiction by analyzing brain networks"
                className="comp4_4_7"
                height={140}
                id={263}
                src="https://rist.ro/wp-content/uploads/2016/01/alcoholism_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_4_3" id={136}>
            <header className="comp4_4_5" id={202}>
              <h2 className="comp4_4_8" id={264}>
                <a
                  className="comp4_4_11"
                  href="https://rist.ro/understanding-the-mechanisms-of-alcohol-addiction-by-analyzing-brain-networks/"
                  id={321}
                  rel="bookmark"
                >
                  Understanding the mechanisms of alcohol addiction by analyzing
                  brain networks
                </a>
              </h2>
            </header>
            <div className="comp4_4_6" id={203}>
              <p className="comp4_4_9" id={265}>
                Our scientists have worked on mathematical and network
                theoretical models that contribute to preventing relapse in
                alcohol addiction.
              </p>
              <span className="comp4_4_10" id={266}>
                <a
                  className="comp4_4_12"
                  href="https://rist.ro/understanding-the-mechanisms-of-alcohol-addiction-by-analyzing-brain-networks/"
                  id={323}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_4;
