import React from "react";

class Comp4_8 extends React.Component {
  render() {
    return (
      <article className="comp4_8" id={57}>
        <div className="comp4_8_1" id={90}>
          <div className="comp4_8_2" id={147}>
            <a
              aria-hidden="true"
              className="comp4_8_4"
              href="https://rist.ro/intricate-connections-between-the-julia-and-mandelbrot-sets/"
              id={213}
            >
              <img
                alt="Intricate connections between the Julia and Mandelbrot sets"
                className="comp4_8_7"
                height={140}
                id={279}
                src="https://rist.ro/wp-content/uploads/2013/05/danca_mandelbrot_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_8_3" id={148}>
            <header className="comp4_8_5" id={214}>
              <h2 className="comp4_8_8" id={280}>
                <a
                  className="comp4_8_11"
                  href="https://rist.ro/intricate-connections-between-the-julia-and-mandelbrot-sets/"
                  id={333}
                  rel="bookmark"
                >
                  Intricate connections between the Julia and Mandelbrot sets
                </a>
              </h2>
            </header>
            <div className="comp4_8_6" id={215}>
              <p className="comp4_8_9" id={281}>
                RIST’s scientist Marius F. Danca and his colleagues have
                discovered that the Mandelbrot set is not only the set of
                complex plane points for which Julia sets are connected, but
                also the set of all parameter values for which alternated Julia
                sets are disconnected.
              </p>
              <span className="comp4_8_10" id={282}>
                <a
                  className="comp4_8_12"
                  href="https://rist.ro/intricate-connections-between-the-julia-and-mandelbrot-sets/"
                  id={335}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_8;
