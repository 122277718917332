import React from 'react';
import {SlotContext} from './SlotContext';

var Comps = require('./CompLoader').default;

class SlotsLoader extends React.Component {
  constructor(params) {
    super(params)
    this.state = { slots: {} }
    
    //TODO: sanity check (maybe as util)
    if (this.props.extractedSite) {
      Comps.load(this.props.extractedSite);
      var jsonSlotsMapping = require(`./../data/${this.props.extractedSite}/react_components/slots.json`);
      this.state.slots = renderDatatoSlotsMapping(jsonSlotsMapping, Comps);
    }

    if ('slots' in this.props) { //overwrite
      for (var slot in this.props.slots) {
        this.state.slots[slot] = this.props.slots[slot];
      }
    }
  }

  render() {
    return (
      <SlotContext.Provider value={{ mapping: this.state.slots }}>
        <this.props.template.type />
      </SlotContext.Provider>
    )
  }
}

function renderDatatoSlotsMapping (jsonSlotsMapping, Comps) {
  var slots = {};
  Object.keys(jsonSlotsMapping).forEach(function (key) {
    slots[key] = jsonSlotsMapping[key].map((compName) => {
      return React.createElement(Comps[compName], { key: compName })
    })
  });

  return slots
}

export default SlotsLoader;
export {renderDatatoSlotsMapping};