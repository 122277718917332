import React from "react";

class Comp4_11 extends React.Component {
  render() {
    return (
      <article className="comp4_11">
        <div className="comp4_11_1">
          <div className="comp4_11_2">
            <a
              aria-hidden="true"
              className="comp4_11_4"
              href="https://rist.ro/the-brains-timescale-correlates-to-the-timescale-of-visual-stimuli/"
            >
              <img
                alt="The brain\u2019s timescale correlates to the timescale of visual stimuli"
                className="comp4_11_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2011/02/timescale_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_11_3">
            <header className="comp4_11_5">
              <h2 className="comp4_11_8">
                <a
                  className="comp4_11_11"
                  href="https://rist.ro/the-brains-timescale-correlates-to-the-timescale-of-visual-stimuli/"
                  rel="bookmark"
                >
                  The brain’s timescale correlates to the timescale of visual
                  stimuli
                </a>
              </h2>
            </header>
            <div className="comp4_11_6">
              <p className="comp4_11_9">
                Our scientists have found that the internal timescale of the
                brain, i.e., the time window needed by neurons to encode a given
                aspect of the visual stimulus, is tightly correlated to the
                external timescale of the visual stimulus, i.e., the speed with
                which the visual image on the retina changes.
              </p>
              <span className="comp4_11_10">
                <a
                  className="comp4_11_12"
                  href="https://rist.ro/the-brains-timescale-correlates-to-the-timescale-of-visual-stimuli/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_11;
