//import React from 'react'; //not needed because not-a-component

//TODO: multiple websites support
let Components = {
    index: {},  //useful to easly iterate based on extracted grouping
    load: function (site) {
        //TODO: sanity check site for escaping path
        const compsPath = `data/${site}/react_components`; //wierd bug require wants /../ inside
        var componentsData = require(`./../${compsPath}/index.json`);
        var componentsMeta = require(`./../${compsPath}/metadata.json`);
        this.index = {}
    
        componentsData.forEach((compName) => {
            var namePieces = compName.split('_');
            var classNumber = namePieces[0].replace("comp", "");
            var subClassNumber = namePieces[1] || undefined;
            var name = `Comp${classNumber}${(subClassNumber ? `_${subClassNumber}` : '')}`;
                
            if (!(classNumber in this.index)) this.index[classNumber] = [];
            this.index[classNumber].push(name);
    
            this[name] = function () { //this is used for JSX addressing
                require(`./../${compsPath}/styles/${name.toLowerCase()}.css`);
                /* eslint-disable */
                return require(`./../${compsPath}/${name}`).default;
            }();
        })
        this['_metadata'] = componentsMeta;
        return this;
    }
}

export default Components;