import React from 'react';
import SlotsLoader, { renderDatatoSlotsMapping } from '../pageframework/SlotsLoader';

import HifiRistTemplate from './HifiRistTemplate' //TODO: move and load from /data

var Comps = require('../pageframework/CompLoader').default;
var Rist = Comps.load('rist.ro')


class SlottedRist extends React.Component {
  render() {
    var slotsOverrides = this.getSlotsFromNamesExample()
    return <SlotsLoader template={<HifiRistTemplate/>} slots={slotsOverrides} extractedSite="rist.ro"/>
  }

  getSlotsFromNamesExample() {
    var news = Array.from(Array(12), (_, i) => "Comp4_" + ++i);
    news.reverse();
    news.push('Comp11');

    var mainNewsSlotComps = renderDatatoSlotsMapping({ main: news }, Comps)
    return mainNewsSlotComps;
  }

  // alternative way to do it by not loading JSON file with slots
  getSlotsFromCompsExample() {
    var news = Array.from(Array(12), (_, i) => {
      var name = "Comp4_" + ++i;
      return React.createElement(Comps[name], { key: name })
    });

    var slotsMapping = {
      header: <Rist.Comp7 />,
      nav: <Rist.Comp1 />,
      hero: <Rist.Comp14 />,

      main: news.concat(<Rist.Comp11 />),
      right: [<Rist.Comp2_1 />, <Rist.Comp2_2 />, <Rist.Comp3_1 />, <Rist.Comp3_2 />],

      'footerL-header': <Rist.Comp12 />,
      'footerL-social': [<Rist.Comp5 />, <Rist.Comp13 />],
      footerM: <Rist.Comp9 />,
      footerR: <Rist.Comp10 />,

      copyright: <span>me</span>
    }

    return slotsMapping;
  }
};

export default SlottedRist;