import React from "react";

class Comp5 extends React.Component {
  render() {
    return (
      /* eslint-disable */
      <ul className="comp5" id={243}>
        <li className="comp5_1" id={299}>
          <a
            className="comp5_5"
            href="https://www.facebook.com/InstitutulRomanDeStiintaSiTehnologie"
            id={350}
            target="_blank"
            title="Facebook"
          >
            Facebook
          </a>
        </li>
        <li className="comp5_2" id={300}>
          <a
            className="comp5_6"
            href="https://twitter.com/rist_ro"
            id={351}
            target="_blank"
            title="Twitter"
          >
            Twitter
          </a>
        </li>
        <li className="comp5_3" id={301}>
          <a
            className="comp5_7"
            href="http://www.linkedin.com/company/romanian-institute-of-science-and-technology"
            id={352}
            target="_blank"
            title="LinkedIn"
          >
            LinkedIn
          </a>
        </li>
        <li className="comp5_4" id={302}>
          <a
            className="comp5_8"
            href="http://coneural.rist.ro/rss.html"
            id={353}
          >
            <img
              alt="fixDebugger"
              className="comp5_9"
              height={24}
              id={407}
              src="https://rist.ro/wp-content/themes/rist/icons/rss.png"
              width={24}
            />
          </a>
        </li>
      </ul>
    );
  }
}

export default Comp5;
