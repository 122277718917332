import React from "react";

class Comp3_2 extends React.Component {
  render() {
    return (
      /* eslint-disable */
      <section className="comp3_2" id={32}>
        <h2 className="comp3_2_1" id={69}>
          Riemannian optimization methods for deep learning
          <img
            className="comp3_2_3"
            height={20}
            id={110}
            src="https://rist.ro/wp-content/themes/rist/icons/icon2_01.png"
            width={22}
          />
        </h2>
        <div className="comp3_2_2" id={70}>
          <p className="comp3_2_4" id={111}>
            Institutul Român de Știință și Tehnologie is the beneficiary of this
            project, the purpose of which is the design and analysis of novel
            training algorithms for neural networks in deep learning, by
            applying notions of Riemannian optimization and differential
            geometry.
          </p>
          <p className="comp3_2_5" id={112}>
            Specific objectives / foreseen results:
          </p>
          <ul className="comp3_2_6" id={113}>
            <li className="comp3_2_9" id={175}>
              The definition of a probabilistic and geometric framework for the
              study of deep neural networks aimed at a better understanding of
              the working mechanisms behind the success of deep learning;
            </li>
            <li className="comp3_2_10" id={176}>
              A complete characterization of the second-order Riemannian and
              affine geometries of a statistical model, aimed at the study of
              second-order optimization methods over statistical manifolds;
            </li>
            <li className="comp3_2_11" id={177}>
              The design and implementation of new first and second-order
              methods for the optimization of functions defined over a
              statistical model, and in particular in the case of the training
              of neural networks;
            </li>
            <li className="comp3_2_12" id={178}>
              An empirical evaluation of the algorithms proposed for the
              training of deep neural networks over standard datasets and in
              industrial competitions, aimed at showing the effectiveness of our
              methods with respect to the state of the art, in the fields of
              image and video, text document and audio document analysis;
            </li>
            <li className="comp3_2_13" id={179}>
              A feasibility study, comprising a market analysis and the
              identification of novel potential markets for successful
              applications of deep learning;
            </li>
            <li className="comp3_2_14" id={180}>
              The implementation of specific innovative demo applications based
              on deep learning technologies, and in particular on the algorithms
              developed during the project.
            </li>
          </ul>
          <p className="comp3_2_7" id={114}>
            The project is co-funded by the European Regional Development Fund
            through the Competitiveness Operational Programme 2014-2020. Total
            value of the project: 8.689.500 lei. Eligible irredeemable value
            from the European Regional Development Fund: 7.276.617 lei. Eligible
            irredeemable value from national budget: 1.340.883 lei.
          </p>
          <p className="comp3_2_8" id={115}>
            <a
              className="comp3_2_15"
              href="http://deepriemann.rist.ro/"
              id={182}
              rel="noopener noreferrer"
              target="_blank"
              title="DeepRiemann"
            >
              Project website
            </a>
          </p>
        </div>
      </section>
    );
  }
}

export default Comp3_2;
