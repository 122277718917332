import React from "react";

class Comp4_1 extends React.Component {
  render() {
    return (
      <article className="comp4_1" id={50}>
        <div className="comp4_1_1" id={83}>
          <div className="comp4_1_2" id={126}>
            <a
              aria-hidden="true"
              className="comp4_1_4"
              href="https://rist.ro/call-for-applications-grants-for-independent-experienced-scientists/"
              id={192}
            >
              <img
                alt="Call for applications: Grants for  experienced scientists"
                className="comp4_1_7"
                height={140}
                id={251}
                src="https://rist.ro/wp-content/uploads/2016/11/smoke_thumb_5.jpg"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_1_3" id={127}>
            <header className="comp4_1_5" id={193}>
              <h2 className="comp4_1_8" id={252}>
                <a
                  className="comp4_1_11"
                  href="https://rist.ro/call-for-applications-grants-for-independent-experienced-scientists/"
                  id={310}
                  rel="bookmark"
                >
                  Call for applications: Grants for experienced scientists
                </a>
              </h2>
            </header>
            <div className="comp4_1_6" id={194}>
              <p className="comp4_1_9" id={253}>
                Our institute invites applications for grants aimed at
                experienced scientists who wish to lead an independent
                scientific research project within the institute.
              </p>
              <span className="comp4_1_10" id={254}>
                <a
                  className="comp4_1_12"
                  href="https://rist.ro/call-for-applications-grants-for-independent-experienced-scientists/"
                  id={312}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_1;
