import React from "react";

class Comp2_2 extends React.Component {
  render() {
    return (
      <section className="comp2_2">
        <h2 className="comp2_2_1">
          Joining our team
          <img
            className="comp2_2_3"
            alt=""
            height={26}
            src="https://rist.ro/wp-content/themes/rist/icons/icon1_23.png"
            width={26}
          />
        </h2>
        <div className="comp2_2_2">
          <p className="comp2_2_4">
            We welcome new scientists who would like to join our institute as
            principal investigators.
            <a
              className="comp2_2_5"
              href="https://rist.ro/call-for-scientists/"
            >
              .
            </a>
          </p>
        </div>
      </section>
    );
  }
}

export default Comp2_2;
