import React from 'react';
import './styles/RistLayout.css';

var Comps = require('../pageframework/CompLoader').default
const Rist = Comps.load('rist.ro');

function RistLayout() {
  return (
    <div className="RistLayoutApp">
      <Rist.Comp7/>
      <Rist.Comp1/>
      <Rist.Comp14/>

      <div class="two-col-layout">
        <div class="column1">
          {Array(12).fill(<Rist.Comp4_1/>)}
          
          <Rist.Comp11/>
        </div>
        <div class="column2">
          <Rist.Comp2_1/>
          <Rist.Comp2_2/>
          <Rist.Comp3_1/>
          <Rist.Comp3_2/>
        </div>
      </div>

      <div class="three-col-layout">
        <div class="column1">
          <Rist.Comp12/>
          <Rist.Comp5/>
          <Rist.Comp13/>
        </div>
        <div class="column2">
          <Rist.Comp9/>
        </div>
        <div class="column3">
          <Rist.Comp10/>
        </div>
      </div>
      <Rist.Comp8/>
		</div>
  );
}

export default RistLayout;
