import React from "react";

class Comp14 extends React.Component {
  render() {
    return (
      <div className="comp14">
        <div className="comp14_1">
          <div className="comp14_2">
            <div className="comp14_4" data-mode="fill">
              <div className="comp14_6">
                <div
                  className="comp14_8"
                  data-alt
                  data-blur={0}
                  data-desktop="//rist.ro/wp-content/uploads/2018/09/neuroni.jpg"
                  data-hash="c32059bfa5158ea7715985b531d14f00"
                  data-title
                ></div>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="comp14_3"
            data-first={1}
            data-id={15}
            data-slide-duration={0}
            data-title="The chronotron"
          >
            <div
              className="comp14_5"
              data-csstextalign="center"
              data-desktopportraitpadding="0|*|0|*|0|*|0"
              data-pm="default"
              data-sstype="slide"
            >
              <div
                className="comp14_7"
                data-adaptivefont={0}
                data-csstextalign="left"
                data-desktoplandscape={1}
                data-desktopportrait={1}
                data-desktopportraitalign="left"
                data-desktopportraitfontsize={100}
                data-desktopportraitgutter={20}
                data-desktopportraitheight={250}
                data-desktopportraitinneralign="left"
                data-desktopportraitleft={18}
                data-desktopportraitpadding="20|*|10|*|0|*|10|*|px+"
                data-desktopportraitparentalign="center"
                data-desktopportraitparentvalign="middle"
                data-desktopportraittop={20}
                data-desktopportraitvalign="top"
                data-desktopportraitwidth={462}
                data-desktopportraitwrapafter={0}
                data-frontend-fullwidth={1}
                data-mobilelandscape={1}
                data-mobilelandscapewrapafter={1}
                data-mobileportrait={1}
                data-mobileportraitalign="left"
                data-mobileportraitheight={773}
                data-mobileportraitleft={18}
                data-mobileportraittop={-271}
                data-mobileportraitvalign="top"
                data-mobileportraitwidth={893}
                data-mobileportraitwrapafter={1}
                data-parentid
                data-plugin="rendered"
                data-pm="absolute"
                data-responsiveposition={1}
                data-responsivesize={1}
                data-rotation={0}
                data-sstype="row"
                data-tabletlandscape={1}
                data-tabletportrait={1}
                data-tabletportraitalign="left"
                data-tabletportraitgutter={0}
                data-tabletportraitheight={269}
                data-tabletportraitleft={18}
                data-tabletportraitpadding="0|*|10|*|10|*|10|*|px+"
                data-tabletportraittop={28}
                data-tabletportraitvalign="top"
                data-tabletportraitwidth={687}
              >
                <div className="comp14_9" row-wrapped={0}>
                  <div
                    className="comp14_10"
                    data-adaptivefont={0}
                    data-colwidthpercent={100}
                    data-csstextalign="left"
                    data-desktoplandscape={1}
                    data-desktopportrait={1}
                    data-desktopportraitfontsize={100}
                    data-desktopportraitinneralign="left"
                    data-desktopportraitmaxwidth={0}
                    data-desktopportraitorder={0}
                    data-desktopportraitpadding="10|*|0|*|0|*|15|*|px+"
                    data-desktopportraitverticalalign="space-around"
                    data-has-maxwidth={0}
                    data-mobilelandscape={1}
                    data-mobileportrait={1}
                    data-mobileportraitpadding="40|*|40|*|40|*|40|*|px+"
                    data-plugin="rendered"
                    data-pm="default"
                    data-r={0}
                    data-rotation={0}
                    data-sstype="col"
                    data-tabletlandscape={1}
                    data-tabletportrait={1}
                    data-tabletportraitmaxwidth={0}
                  >
                    <div
                      className="comp14_11"
                      data-verticalalign="space-around"
                    >
                      <div
                        className="comp14_12"
                        data-adaptivefont={0}
                        data-cssselfalign="inherit"
                        data-desktoplandscape={1}
                        data-desktopportrait={1}
                        data-desktopportraitfontsize={100}
                        data-desktopportraitheight={0}
                        data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+"
                        data-desktopportraitmaxwidth={300}
                        data-desktopportraitselfalign="inherit"
                        data-has-maxwidth={1}
                        data-mobilelandscape={1}
                        data-mobileportrait={1}
                        data-mobileportraitfontsize={250}
                        data-plugin="rendered"
                        data-pm="normal"
                        data-rotation={0}
                        data-sstype="layer"
                        data-tabletlandscape={1}
                        data-tabletportrait={1}
                        data-tabletportraitfontsize={120}
                      >
                        <div className="comp14_15">The chronotron</div>
                      </div>
                      <div
                        className="comp14_13"
                        data-adaptivefont={0}
                        data-cssselfalign="inherit"
                        data-desktoplandscape={1}
                        data-desktopportrait={1}
                        data-desktopportraitfontsize={100}
                        data-desktopportraitheight={0}
                        data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+"
                        data-desktopportraitmaxwidth={600}
                        data-desktopportraitselfalign="inherit"
                        data-has-maxwidth={1}
                        data-mobilelandscape={1}
                        data-mobileportrait={1}
                        data-mobileportraitfontsize={250}
                        data-mobileportraitmaxwidth={270}
                        data-plugin="rendered"
                        data-pm="normal"
                        data-rotation={0}
                        data-sstype="layer"
                        data-tabletlandscape={1}
                        data-tabletportrait={1}
                        data-tabletportraitfontsize={130}
                        data-tabletportraitmaxwidth={550}
                      >
                        <div className="comp14_16">
                          <div className="comp14_18">
                            <p className="comp14_20">
                              a neuron that learns to fire
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="comp14_14"
                        data-adaptivefont={0}
                        data-cssselfalign="inherit"
                        data-desktoplandscape={1}
                        data-desktopportrait={1}
                        data-desktopportraitfontsize={100}
                        data-desktopportraitheight={0}
                        data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+"
                        data-desktopportraitmaxwidth={0}
                        data-desktopportraitselfalign="inherit"
                        data-has-maxwidth={0}
                        data-mobilelandscape={1}
                        data-mobileportrait={1}
                        data-plugin="rendered"
                        data-pm="normal"
                        data-rotation={0}
                        data-sstype="layer"
                        data-tabletlandscape={1}
                        data-tabletportrait={1}
                      >
                        <div className="comp14_17">
                          <a
                            className="comp14_19"
                            href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
                            tabIndex={-1}
                          >
                            <div className="comp14_21">
                              <div className="comp14_22">Find out more</div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Comp14;
