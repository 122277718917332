import React from "react";

class Comp4_11 extends React.Component {
  render() {
    return (
      <article className="comp4_11" id={60}>
        <div className="comp4_11_1" id={93}>
          <div className="comp4_11_2" id={156}>
            <a
              aria-hidden="true"
              className="comp4_11_4"
              href="https://rist.ro/the-brains-timescale-correlates-to-the-timescale-of-visual-stimuli/"
              id={222}
            >
              <img
                alt="The brain\u2019s timescale correlates to the timescale of visual stimuli"
                className="comp4_11_7"
                height={140}
                id={291}
                src="https://rist.ro/wp-content/uploads/2011/02/timescale_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_11_3" id={157}>
            <header className="comp4_11_5" id={223}>
              <h2 className="comp4_11_8" id={292}>
                <a
                  className="comp4_11_11"
                  href="https://rist.ro/the-brains-timescale-correlates-to-the-timescale-of-visual-stimuli/"
                  id={344}
                  rel="bookmark"
                >
                  The brain’s timescale correlates to the timescale of visual
                  stimuli
                </a>
              </h2>
            </header>
            <div className="comp4_11_6" id={224}>
              <p className="comp4_11_9" id={293}>
                Our scientists have found that the internal timescale of the
                brain, i.e., the time window needed by neurons to encode a given
                aspect of the visual stimulus, is tightly correlated to the
                external timescale of the visual stimulus, i.e., the speed with
                which the visual image on the retina changes.
              </p>
              <span className="comp4_11_10" id={294}>
                <a
                  className="comp4_11_12"
                  href="https://rist.ro/the-brains-timescale-correlates-to-the-timescale-of-visual-stimuli/"
                  id={346}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_11;
