import React from "react";

class Comp13 extends React.Component {
  render() {
    return (
      /* eslint-disable */
      <p className="comp13" id={244}>
        <a
          className="comp13_1"
          href="http://eepurl.com/wor3r"
          id={303}
          target="_blank"
          title="Abonare la \u015Ftirile noastre"
        >
          Subscribe to our newsletter
        </a>
      </p>
    );
  }
}

export default Comp13;
