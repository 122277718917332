import React from "react";

class Comp12 extends React.Component {
  render() {
    return (
      <div className="comp12">
        <h3 className="comp12_1">Connect</h3>
      </div>
    );
  }
}

export default Comp12;
