import React from "react";

class Comp4_10 extends React.Component {
  render() {
    return (
      <article className="comp4_10">
        <div className="comp4_10_1">
          <div className="comp4_10_2">
            <a
              aria-hidden="true"
              className="comp4_10_4"
              href="https://rist.ro/finding-attractors-of-continuous-time-systems-by-parameter-switching/"
            >
              <img
                alt="Finding attractors of continuous-time systems by parameter switching"
                className="comp4_10_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2011/09/attractor_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_10_3">
            <header className="comp4_10_5">
              <h2 className="comp4_10_8">
                <a
                  className="comp4_10_11"
                  href="https://rist.ro/finding-attractors-of-continuous-time-systems-by-parameter-switching/"
                  rel="bookmark"
                >
                  Finding attractors of continuous-time systems by parameter
                  switching
                </a>
              </h2>
            </header>
            <div className="comp4_10_6">
              <p className="comp4_10_9">
                RIST’s Marius F. Danca has found that if the control parameter
                <i className="comp4_10_12">
                  , of a continuous-time nonlinear system belonging to a large
                  class of systems, is switched within a set of chosen values in
                  a deterministic or even random manner, while the underlying
                  model is numerically integrated, the obtained attractor is a
                  numerical approximation of one of the existing attractors of
                  the considered system.
                </i>
              </p>
              <span className="comp4_10_10">
                <a
                  className="comp4_10_13"
                  href="https://rist.ro/finding-attractors-of-continuous-time-systems-by-parameter-switching/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_10;
