import React from "react";

class Comp11 extends React.Component {
  render() {
    return (
      <div className="comp11" id={62}>
        <p className="comp11_1" id={95}>
          <a
            className="comp11_2"
            href="https://rist.ro/category/news/"
            id={161}
          >
            More news
          </a>
        </p>
      </div>
    );
  }
}

export default Comp11;
