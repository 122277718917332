import React from 'react';

import { FormControl, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string';


class MainInputForm extends React.Component {
    constructor(props) {
        super(props);
        let previousUrl = queryString.parse(this.props.location.search).url;
        this.state = { url: (previousUrl ? previousUrl : 'https://www.rist.ro/') };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ url: event.target.value });
    }

    handleSubmit(event) {
        this.props.history.push(`/disassm/showcase?url=${this.state.url}`);
        event.preventDefault();
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <InputGroup>
                        <FormControl id="fix-my-input"
                            value={this.state.url} onChange={this.handleChange}
                            placeholder="https://www.example.com/"
                            aria-label="Website to dissasemble"
                            readOnly
                        />
                        <InputGroup.Append>
                            <DropdownButton title="Dissasemble" id="fix-my-css">
                                <Dropdown.Item href={`/disassm/showcase?url=${this.state.url}`}>Components</Dropdown.Item>
                                <hr></hr>
                                <Dropdown.Item href={`/disassm/simple?url=${this.state.url}`}>Simple Layout</Dropdown.Item>
                                <Dropdown.Item href={`/disassm/slotted?url=${this.state.url}`}>Slotted Layout</Dropdown.Item>
                                <Dropdown.Item href={`/disassm/draggable?url=${this.state.url}`}>Draggable Layout</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup.Append>
                    </InputGroup>
                </form>
            </div>
        );
    }
}


export default withRouter(MainInputForm);