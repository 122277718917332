import React from "react";

class Comp4_4 extends React.Component {
  render() {
    return (
      <article className="comp4_4">
        <div className="comp4_4_1">
          <div className="comp4_4_2">
            <a
              aria-hidden="true"
              className="comp4_4_4"
              href="https://rist.ro/understanding-the-mechanisms-of-alcohol-addiction-by-analyzing-brain-networks/"
            >
              <img
                alt="Understanding the mechanisms of alcohol addiction by analyzing brain networks"
                className="comp4_4_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2016/01/alcoholism_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_4_3">
            <header className="comp4_4_5">
              <h2 className="comp4_4_8">
                <a
                  className="comp4_4_11"
                  href="https://rist.ro/understanding-the-mechanisms-of-alcohol-addiction-by-analyzing-brain-networks/"
                  rel="bookmark"
                >
                  Understanding the mechanisms of alcohol addiction by analyzing
                  brain networks
                </a>
              </h2>
            </header>
            <div className="comp4_4_6">
              <p className="comp4_4_9">
                Our scientists have worked on mathematical and network
                theoretical models that contribute to preventing relapse in
                alcohol addiction.
              </p>
              <span className="comp4_4_10">
                <a
                  className="comp4_4_12"
                  href="https://rist.ro/understanding-the-mechanisms-of-alcohol-addiction-by-analyzing-brain-networks/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_4;
