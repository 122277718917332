import React from 'react';
import { SlotContext } from './SlotContext';

import './styles/Slot.css';


class Slot extends React.Component {
    constructor(params) {
        super(params)
        this.state = { x: 0, y: 0, width: 0, height: 0 };
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }
    handleKeyDown(event) {
        if (event.keyCode === 'K'.charCodeAt(0)) {//K
            document.querySelectorAll('.pfSlot').forEach(e => {
                e.classList.toggle("overlay");
            });
        }
    }
    render() {
        var self = this;
        return <SlotContext.Consumer>
            {(slots) => (
                <div className={"pfSlot"} name={self.props.name}>
                    <div className="SlotContent" ref={(divElement) => { this.divElement = divElement }}>
                        {slots.mapping[self.props.name] || self.props.children || `[ ${self.props.name} ]`}
                    </div>
                    <div className="SlotOverlay" style={{
                        width: this.state.width + "px",
                        height: this.state.height + "px",
                    }}>
                        <span>
                            { JSON.stringify({
                                //grid ready debug-info
                                i: self.props.name,
                                x: Math.round(this.state.x*2/100)/2,
                                y: Math.round(this.state.x*2/50)/2,
                                w: Math.round(this.state.width*2/100)/2,
                                h: Math.round(this.state.height*2/50)/2,
                                //moved: false,
                                //static: false
                            }) }
                        </span>
                    </div>
                </div>
            )}
        </SlotContext.Consumer>
    }
    componentDidMount() {
        var position = this.divElement.getBoundingClientRect();
        //TODO: scroll offset?
        var x = position.left;
        var y = position.top;
        this.setState({ x, y });

        const width = this.divElement.clientWidth;
        const height = this.divElement.clientHeight;
        this.setState({ width, height });

        document.addEventListener("keydown", this.handleKeyDown, false);

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }
};

Slot.contextType = SlotContext;

export default Slot;