import React from "react";

class Comp4_1 extends React.Component {
  render() {
    return (
      <article className="comp4_1">
        <div className="comp4_1_1">
          <div className="comp4_1_2">
            <a
              aria-hidden="true"
              className="comp4_1_4"
              href="https://rist.ro/marie-sklodowska-curie-individual-fellowships/"
            >
              <img
                alt="Marie Sk\u0142odowska-Curie Individual Fellowships"
                className="comp4_1_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2018/05/smoke_thumb_3.jpg"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_1_3">
            <header className="comp4_1_5">
              <h2 className="comp4_1_8">
                <a
                  className="comp4_1_11"
                  href="https://rist.ro/marie-sklodowska-curie-individual-fellowships/"
                  rel="bookmark"
                >
                  Marie Skłodowska-Curie Individual Fellowships
                </a>
              </h2>
            </header>
            <div className="comp4_1_6">
              <p className="comp4_1_9">
                The Romanian Institute of Science and Technology welcomes Marie
                Skłodowska-Curie Individual Fellowship applications.
              </p>
              <span className="comp4_1_10">
                <a
                  className="comp4_1_12"
                  href="https://rist.ro/marie-sklodowska-curie-individual-fellowships/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_1;
