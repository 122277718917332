import React from "react";

class Comp6 extends React.Component {
  render() {
    return (
      <div className="comp6" id={250}>
        <div className="comp6_1" id={306}>
          <div
            aria-label="The chronotron"
            className="comp6_5"
            id={358}
            role="button"
            tabIndex={0}
          ></div>
        </div>
        <div className="comp6_2" id={307}>
          <div
            aria-label="Intricate connections"
            className="comp6_6"
            id={359}
            role="button"
            tabIndex={0}
          ></div>
        </div>
        <div className="comp6_3" id={308}>
          <div
            aria-current="true"
            aria-label="New metrics"
            className="comp6_7"
            id={360}
            role="button"
          ></div>
        </div>
        <div className="comp6_4" id={309}>
          <div
            aria-label="Resonance"
            className="comp6_8"
            id={361}
            role="button"
            tabIndex={0}
          ></div>
        </div>
      </div>
    );
  }
}

export default Comp6;
