import React from "react";

class Comp7 extends React.Component {
  render() {
    return (
      <header className="comp7" id={2} role="banner">
        <div className="comp7_1" id={6}>
          <div className="comp7_2" id={12}>
            <li className="comp7_4" id={20}>
              <a
                className="comp7_6"
                href="https://rist.ro/ro/"
                hrefLang="ro-RO"
                id={37}
                lang="ro-RO"
              >
                Română
              </a>
            </li>
          </div>
          <div className="comp7_3" id={13}>
            <a className="comp7_5" href="/" id={21} itemProp="url" rel="home">
              <img
                alt="RIST"
                className="comp7_7"
                id={38}
                itemProp="logo"
                src="https://rist.ro/wp-content/uploads/2018/12/logo-RIST-en-blue.svg"
                width={304}
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Comp7;
