import React from "react";

class Comp9 extends React.Component {
  render() {
    return (
      <div className="comp9" id={35}>
        <div className="comp9_1" id={73}>
          <div className="comp9_2" id={119}>
            <h3 className="comp9_4" id={185}>
              Support the institute
            </h3>
          </div>
          <div className="comp9_3" id={120}>
            <ul className="comp9_5" id={186}>
              <li className="comp9_6" id={247}>
                <a
                  className="comp9_7"
                  href="/directionati-2-din-impozit-catre-institutul-roman-de-stiinta-si-tehnologie/"
                  id={304}
                  title="Opens internal link in current window"
                >
                  Forward to the institute 2% of the tax on revenue
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Comp9;
