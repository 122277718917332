import React from "react";

class Comp4_9 extends React.Component {
  render() {
    return (
      <article className="comp4_9" id={58}>
        <div className="comp4_9_1" id={91}>
          <div className="comp4_9_2" id={150}>
            <a
              aria-hidden="true"
              className="comp4_9_4"
              href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
              id={216}
            >
              <img
                alt="The chronotron \u2013 a neuron that learns to fire temporally precise spike patterns"
                className="comp4_9_7"
                height={140}
                id={283}
                src="https://rist.ro/wp-content/uploads/2012/08/chronotron_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_9_3" id={151}>
            <header className="comp4_9_5" id={217}>
              <h2 className="comp4_9_8" id={284}>
                <a
                  className="comp4_9_11"
                  href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
                  id={336}
                  rel="bookmark"
                >
                  The chronotron – a neuron that learns to fire temporally
                  precise spike patterns
                </a>
              </h2>
            </header>
            <div className="comp4_9_6" id={218}>
              <p className="comp4_9_9" id={285}>
                Răzvan Florian has shown how neurons can learn to process and
                memorize information that is entirely temporally coded, both as
                input and as output, in the precise timings of spikes.
              </p>
              <span className="comp4_9_10" id={286}>
                <a
                  className="comp4_9_12"
                  href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
                  id={338}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_9;
