import React from "react";

class Comp9 extends React.Component {
  render() {
    return (
      <div className="comp9">
        <div className="comp9_1">
          <div className="comp9_2">
            <h3 className="comp9_4">Support the institute</h3>
          </div>
          <div className="comp9_3">
            <ul className="comp9_5">
              <li className="comp9_6">
                <a
                  className="comp9_7"
                  href="/directionati-2-din-impozit-catre-institutul-roman-de-stiinta-si-tehnologie/"
                  title="Opens internal link in current window"
                >
                  Forward to the institute 2% of the tax on revenue
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Comp9;
