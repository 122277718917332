import React from "react";

class Comp10 extends React.Component {
  render() {
    return (
      <div className="comp10">
        <div className="comp10_1">
          <h3 className="comp10_2">Search</h3>
          <form
            action="https://rist.ro/"
            className="comp10_3"
            method="get"
            role="search"
          >
            <label className="comp10_4">
              <input
                className="comp10_6"
                name="s"
                placeholder="Search \u2026"
                type="search"
                defaultValue
              />
            </label>
            <button className="comp10_5" type="submit"></button>
          </form>
        </div>
      </div>
    );
  }
}

export default Comp10;
