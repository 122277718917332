import React from "react";

class Comp4_3 extends React.Component {
  render() {
    return (
      <article className="comp4_3">
        <div className="comp4_3_1">
          <div className="comp4_3_2">
            <a
              aria-hidden="true"
              className="comp4_3_4"
              href="https://rist.ro/transylvanian-machine-learning-summer-school/"
            >
              <img
                alt="Transylvanian Machine Learning Summer School"
                className="comp4_3_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2018/02/TMLSS_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_3_3">
            <header className="comp4_3_5">
              <h2 className="comp4_3_8">
                <a
                  className="comp4_3_11"
                  href="https://rist.ro/transylvanian-machine-learning-summer-school/"
                  rel="bookmark"
                >
                  Transylvanian Machine Learning Summer School
                </a>
              </h2>
            </header>
            <div className="comp4_3_6">
              <p className="comp4_3_9">
                Our institute supported, as partner, the first edition of the
                Transylvanian Machine Learning Summer School (TMLSS), which took
                place in Cluj, Transylvania, Romania in July 16-22, 2018. The
                focus of school was on Deep Learning and Reinforcement
                Learning. The school was organized by machine learning
                researchers from DeepMind (Răzvan Pașcanu, Viorica Pătrăucean),
                Romanian Institute of Science and Technology (Luigi Malagò,
                Răzvan Florian), and McGill University & DeepMind (Doina
                Precup).
              </p>
              <span className="comp4_3_10">
                <a
                  className="comp4_3_12"
                  href="https://rist.ro/transylvanian-machine-learning-summer-school/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_3;
