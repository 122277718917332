import React from "react";

class Comp3_1 extends React.Component {
  render() {
    return (
      <section className="comp3_1">
        <h2 className="comp3_1_1">
          Automated software development through abstraction in deep,
          distributed computational models
          <img
            className="comp3_1_3"
            height={20}
            alt={"zzz"}
            src="https://rist.ro/wp-content/themes/rist/icons/icon2_01.png"
            width={22}
          />
        </h2>
        <div className="comp3_1_2">
          <p className="comp3_1_4">
            Institutul Român de Știință și Tehnologie is the beneficiary of this
            project, the purpose of which is the development of disruptive
            methods for automatizing software development and for simplifying
            the work of programmers, by applying state of the art machine
            learning technologies and cognitive theories.
          </p>
          <p className="comp3_1_5">Specific objectives / foreseen results:</p>
          <ul className="comp3_1_6">
            <li className="comp3_1_9">
              Methods for automated creation of of new implementations of
              software;
            </li>
            <li className="comp3_1_10">
              Tools to help programmers debug and maintain complex software and
              to facilitate software development.
            </li>
          </ul>
          <p className="comp3_1_7">
            The project is co-funded by the European Regional Development Fund
            through the Competitiveness Operational Programme 2014-2020. Total
            value of the project: 8.687.180 lei. Eligible irredeemable value
            from the European Regional Development Fund: 7.274.674,88 lei.
            Eligible irredeemable value from national budget: 1.340.525,12 lei.
          </p>
          <p className="comp3_1_8">
            <a
              className="comp3_1_11"
              href="http://autoware.rist.ro/"
              rel="noopener noreferrer"
              target="_blank"
              title="AutoWare"
            >
              Project website
            </a>
          </p>
        </div>
      </section>
    );
  }
}

export default Comp3_1;
