import React from "react";

class Comp4_7 extends React.Component {
  render() {
    return (
      <article className="comp4_7">
        <div className="comp4_7_1">
          <div className="comp4_7_2">
            <a
              aria-hidden="true"
              className="comp4_7_4"
              href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
            >
              <img
                alt="Neuronal membrane resonance enables stable and robust gamma oscillations in the brain"
                className="comp4_7_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2014/03/resonance_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_7_3">
            <header className="comp4_7_5">
              <h2 className="comp4_7_8">
                <a
                  className="comp4_7_11"
                  href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
                  rel="bookmark"
                >
                  Neuronal membrane resonance enables stable and robust gamma
                  oscillations in the brain
                </a>
              </h2>
            </header>
            <div className="comp4_7_6">
              <p className="comp4_7_9">
                Vasile V. Moca, Raul C. Mureșan and colleagues have recently
                shown that membrane resonance, a property of inhibitory neurons,
                may hold the key to the emergence of robust and stable gamma
                oscillations in the brain.
              </p>
              <span className="comp4_7_10">
                <a
                  className="comp4_7_12"
                  href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_7;
