import React from 'react';

import {  Alert } from 'react-bootstrap';



function InfoBox(props) {
    const [show, setShow] = React.useState(true);

    if (show) {
        return (
            <div className="container vertical-center">

            <Alert variant="info" onClose={() => setShow(false)} dismissible>
                {/* <Alert.Heading>Tips</Alert.Heading>} */}
                {props.children}
            </Alert>
            </div>
        );
    } else {
        //return <Button onClick={() => setShow(true)}>Show Alert</Button>;
        return <></>
    }
}

export default InfoBox;