import React from 'react';
import './styles/App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import MainInputForm from './MainInputForm';
import InfoBox from './InfoBox';


import CompShowcase from './layouts/CompsShowcase';
import SimpleRistLayout from './layouts/SimpleRistLayout';
import SlottedRist from './layouts/SlottedRist';
import DraggableShowcase from './layouts/DraggableShowcase';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>

          <Route exact path={["/", "/disassm"]}>
            <div className="container align-self-center w-50 mt-5">
              <MainInputForm />
            </div>
          </Route>
          <Route path="/disassm/">
            <div className="container vertical-center sticky-top">
              <MainInputForm />
            </div>

            <Router>
              <Switch>
                <Route exact path="/disassm/showcase">
                  <InfoBox>
                    Click on the <b>components showcase number</b> with Developer Tools open to jump to the React code of the component.
                  </InfoBox>
                  <CompShowcase extractedSite="rist.ro" />
                </Route>
                <Route exact path="/disassm/simple">
                  <InfoBox>
                  Notice how expressive the layout code is.
                  </InfoBox>
                  <SimpleRistLayout />
                </Route>
                <Route exact path="/disassm/slotted">
                  <InfoBox>
                    Try pressing 'k' in the <b>Slotted Layout</b> to see the slots.
                    </InfoBox>
                  <SlottedRist />
                </Route>
                <Route exact path="/disassm/draggable">
                  <InfoBox>
                    <b>Drag by handles</b>, resize from lower right, use 'k' to toggle overlays, and 'o' and 'd' for reordering.
                  </InfoBox>
                  <DraggableShowcase extractedSite="rist.ro" />
                </Route>
              </Switch>
            </Router>

          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
