import React from "react";

class Comp2_1 extends React.Component {
  render() {
    return (
      /* eslint-disable */
      <section className="comp2_1" id={29}>
        <h2 className="comp2_1_1" id={63}>
          2% for RIST
          <img
            className="comp2_1_3"
            height={20}
            id={97}
            src="https://rist.ro/wp-content/themes/rist/icons/icon2_01.png"
            width={22}
          />
        </h2>
        <div className="comp2_1_2" id={64}>
          <p className="comp2_1_4" id={98}>
            Want to support science? If you live in Romania, you may forward to
            RIST
            <a
              className="comp2_1_5"
              href="https://rist.ro/directionati-2-din-impozit-catre-institutul-roman-de-stiinta-si-tehnologie/"
              id={163}
              title="Opens internal link in current window"
            >
              2% of the revenue tax you already paid.
            </a>
          </p>
        </div>
      </section>
    );
  }
}

export default Comp2_1;
