import React from "react";

class Comp4_12 extends React.Component {
  render() {
    return (
      <article className="comp4_12" id={61}>
        <div className="comp4_12_1" id={94}>
          <div className="comp4_12_2" id={159}>
            <a
              aria-hidden="true"
              className="comp4_12_4"
              href="https://rist.ro/visualizing-how-the-brain-encodes-information/"
              id={225}
            >
              <img
                alt="Visualizing how the brain encodes information"
                className="comp4_12_7"
                height={140}
                id={295}
                src="https://rist.ro/wp-content/uploads/2009/10/visualising_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_12_3" id={160}>
            <header className="comp4_12_5" id={226}>
              <h2 className="comp4_12_8" id={296}>
                <a
                  className="comp4_12_11"
                  href="https://rist.ro/visualizing-how-the-brain-encodes-information/"
                  id={347}
                  rel="bookmark"
                >
                  Visualizing how the brain encodes information
                </a>
              </h2>
            </header>
            <div className="comp4_12_6" id={227}>
              <p className="comp4_12_9" id={297}>
                The institute’s scientists have developed a special
                visualization technique for how multiple neurons fire spikes
                together to encode information, by representing the identity of
                firing patterns of multiple neurons with color sequences.
              </p>
              <span className="comp4_12_10" id={298}>
                <a
                  className="comp4_12_12"
                  href="https://rist.ro/visualizing-how-the-brain-encodes-information/"
                  id={349}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_12;
