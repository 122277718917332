import React from "react";

class Comp4_6 extends React.Component {
  render() {
    return (
      <article className="comp4_6" id={55}>
        <div className="comp4_6_1" id={88}>
          <div className="comp4_6_2" id={141}>
            <a
              aria-hidden="true"
              className="comp4_6_4"
              href="https://rist.ro/a-new-class-of-spike-train-metrics/"
              id={207}
            >
              <img
                alt="A new class of spike train metrics"
                className="comp4_6_7"
                height={140}
                id={271}
                src="https://rist.ro/wp-content/uploads/2014/03/metrica_thumb2.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_6_3" id={142}>
            <header className="comp4_6_5" id={208}>
              <h2 className="comp4_6_8" id={272}>
                <a
                  className="comp4_6_11"
                  href="https://rist.ro/a-new-class-of-spike-train-metrics/"
                  id={327}
                  rel="bookmark"
                >
                  A new class of spike train metrics
                </a>
              </h2>
            </header>
            <div className="comp4_6_6" id={209}>
              <p className="comp4_6_9" id={273}>
                Cătălin Rusu and Răzvan Florian have developed a series of
                several new metrics for quantifying the differences between
                spike trains. The new metrics are inspired by
                the Pompeiu-Hausdorff distance.
              </p>
              <span className="comp4_6_10" id={274}>
                <a
                  className="comp4_6_12"
                  href="https://rist.ro/a-new-class-of-spike-train-metrics/"
                  id={329}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_6;
