import React from "react";

class Comp4_9 extends React.Component {
  render() {
    return (
      <article className="comp4_9">
        <div className="comp4_9_1">
          <div className="comp4_9_2">
            <a
              aria-hidden="true"
              className="comp4_9_4"
              href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
            >
              <img
                alt="The chronotron \u2013 a neuron that learns to fire temporally precise spike patterns"
                className="comp4_9_7"
                height={140}
                src="https://rist.ro/wp-content/uploads/2012/08/chronotron_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_9_3">
            <header className="comp4_9_5">
              <h2 className="comp4_9_8">
                <a
                  className="comp4_9_11"
                  href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
                  rel="bookmark"
                >
                  The chronotron – a neuron that learns to fire temporally
                  precise spike patterns
                </a>
              </h2>
            </header>
            <div className="comp4_9_6">
              <p className="comp4_9_9">
                Răzvan Florian has shown how neurons can learn to process and
                memorize information that is entirely temporally coded, both as
                input and as output, in the precise timings of spikes.
              </p>
              <span className="comp4_9_10">
                <a
                  className="comp4_9_12"
                  href="https://rist.ro/the-chronotron-a-neuron-that-learns-to-fire-temporally-precise-spike-patterns/"
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_9;
