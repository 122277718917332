import React from "react";

class Comp8 extends React.Component {
  render() {
    return (
      <div className="comp8" id={33}>
        <span className="comp8_1" id={71}>
          © Romanian Institute of Science and Technology
        </span>
      </div>
    );
  }
}

export default Comp8;
