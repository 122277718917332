import React from "react";

class Comp4_7 extends React.Component {
  render() {
    return (
      <article className="comp4_7" id={56}>
        <div className="comp4_7_1" id={89}>
          <div className="comp4_7_2" id={144}>
            <a
              aria-hidden="true"
              className="comp4_7_4"
              href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
              id={210}
            >
              <img
                alt="Neuronal membrane resonance enables stable and robust gamma oscillations in the brain"
                className="comp4_7_7"
                height={140}
                id={275}
                src="https://rist.ro/wp-content/uploads/2014/03/resonance_thumb.png"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_7_3" id={145}>
            <header className="comp4_7_5" id={211}>
              <h2 className="comp4_7_8" id={276}>
                <a
                  className="comp4_7_11"
                  href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
                  id={330}
                  rel="bookmark"
                >
                  Neuronal membrane resonance enables stable and robust gamma
                  oscillations in the brain
                </a>
              </h2>
            </header>
            <div className="comp4_7_6" id={212}>
              <p className="comp4_7_9" id={277}>
                Vasile V. Moca, Raul C. Mureșan and colleagues have recently
                shown that membrane resonance, a property of inhibitory neurons,
                may hold the key to the emergence of robust and stable gamma
                oscillations in the brain.
              </p>
              <span className="comp4_7_10" id={278}>
                <a
                  className="comp4_7_12"
                  href="https://rist.ro/neuronal-membrane-resonance-enables-stable-and-robust-gamma-oscillations-in-the-brain/"
                  id={332}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_7;
