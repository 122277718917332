import React from "react";

class Comp12 extends React.Component {
  render() {
    return (
      <div className="comp12" id={117}>
        <h3 className="comp12_1" id={183}>
          Connect
        </h3>
      </div>
    );
  }
}

export default Comp12;
