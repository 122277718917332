import React from "react";

class Comp4_5 extends React.Component {
  render() {
    return (
      <article className="comp4_5" id={54}>
        <div className="comp4_5_1" id={87}>
          <div className="comp4_5_2" id={138}>
            <a
              aria-hidden="true"
              className="comp4_5_4"
              href="https://rist.ro/nobel-prize-winner-james-watson-visited-our-institute-and-lectured-at-the-transylvanian-experimental-neuroscience-summer-school/"
              id={204}
            >
              <img
                alt="Nobel prize winner James Watson visited our institute and lectured at the Transylvanian Experimental Neuroscience Summer School"
                className="comp4_5_7"
                height={140}
                id={267}
                src="https://rist.ro/wp-content/uploads/2016/06/James_D_Watson_2.jpg"
                width={140}
              />
            </a>
          </div>
          <div className="comp4_5_3" id={139}>
            <header className="comp4_5_5" id={205}>
              <h2 className="comp4_5_8" id={268}>
                <a
                  className="comp4_5_11"
                  href="https://rist.ro/nobel-prize-winner-james-watson-visited-our-institute-and-lectured-at-the-transylvanian-experimental-neuroscience-summer-school/"
                  id={324}
                  rel="bookmark"
                >
                  Nobel prize winner James Watson visited our institute and
                  lectured at the Transylvanian Experimental Neuroscience Summer
                  School
                </a>
              </h2>
            </header>
            <div className="comp4_5_6" id={206}>
              <p className="comp4_5_9" id={269}>
                James Watson has been awarded the 1962 Nobel Prize in Physiology
                or Medicine for his contribution to the discovery of the
                molecular structure of nucleic acids and its significance for
                information transfer in living material.
              </p>
              <span className="comp4_5_10" id={270}>
                <a
                  className="comp4_5_12"
                  href="https://rist.ro/nobel-prize-winner-james-watson-visited-our-institute-and-lectured-at-the-transylvanian-experimental-neuroscience-summer-school/"
                  id={326}
                >
                  read more
                </a>
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Comp4_5;
